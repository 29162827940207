import {
  getFormatPromotions,
  getHighPromotionDiscount,
  isProductInSomePromotion,
} from './getPromotionHelper'
import { applyDiscount, fixAmount, getHighDiscount } from './helpers'

const getFinalDiscount = (
  product,
  cart,
  offers,
  promotions,
  sellerDiscount,
  branchOffice
) => {
  const { descuento, descuentos } = product

  const formatedPromotions = getFormatPromotions(
    promotions,
    product,
    cart,
    branchOffice
  )

  const higherOffer = getHighDiscount(offers)
  const productDiscountFormated = {
    descuento: Math.ceil(parseFloat(descuentos || descuento || 0)),
    codigo: null,
  }

  const sellerDiscountFormated = {
    descuento: Math.ceil(parseFloat(sellerDiscount || 0)),
    codigo: null,
  }

  const highPromotion = getHighPromotionDiscount(formatedPromotions)
  
  let finalDiscount = getHighDiscount([
    sellerDiscountFormated,
    highPromotion,
    higherOffer,
    productDiscountFormated,
  ])

  if(
    highPromotion?.codigo?.includes('PR2') && 
    sellerDiscountFormated?.descuento === productDiscountFormated?.descuento && 
    highPromotion?.descuento && 
    highPromotion.descuento < finalDiscount.descuento 
  ) {
    finalDiscount = highPromotion
  }

  return { ...finalDiscount, codigo: finalDiscount?.codigo || null, oldDiscount: product.descuento }
}

const getProductDiscount = (
  product,
  cart,
  offers,
  promotions,
  sellerDiscount,
  branchOffice
) => {
  const finalDiscount = getFinalDiscount(
    product,
    cart,
    offers,
    promotions,
    sellerDiscount,
    branchOffice
  )
  const discountType = finalDiscount?.codigo?.startsWith('PR')
    ? 'promotion'
    : finalDiscount?.codigo?.startsWith('OF')
      ? 'offer'
      : ''

  const affectedQuantity = finalDiscount.cantidadNoAfectada
    ? finalDiscount.cantidadAfectada
    : product.cantidad

  const notAffectedQuantity = finalDiscount.cantidadNoAfectada || 0

  const priceWithoutDiscount = fixAmount(product?.precioDeLista)
  const priceWithDiscount =
    finalDiscount?.fixAmount ||
    fixAmount(applyDiscount(priceWithoutDiscount, finalDiscount.descuento))

  const totalPriceWithoutDiscount = fixAmount(
    priceWithoutDiscount * affectedQuantity
  )
  const totalPriceWithDiscount = fixAmount(priceWithDiscount * affectedQuantity)
  return {
    discountType, // promotion, oferta, productDiscount, null
    code: finalDiscount?.codigo, // si es promocion o oferta,
    affectedQuantity, // cantidad a la que se le aplica el descuento
    notAffectedQuantity, // cantidad a la que NO se le aplica el descuento
    discount: fixAmount(finalDiscount?.descuento || 0), // 0 - 100,
    oldDiscount: product.descuento,
    priceWithoutDiscount, // precio individual
    totalPriceWithoutDiscount, // precio individual * cantidad
    priceWithDiscount, // precio individual con descuento
    totalPriceWithDiscount, // // precio individual con descuento * cantidad,
  }
}

export const shouldProductHaveSecondaryDiscount = (mainDiscountCode) => {
  const promotionsWithSecondaryDiscount = ['PR3-', 'PR7-', 'PR8-', 'PR10-']
  return promotionsWithSecondaryDiscount.some((code) =>
    mainDiscountCode?.startsWith(code)
  )
}

export const getAllProductDiscount = (
  product,
  cart,
  offers,
  promotions,
  sellerDiscount = 0,
  branchOffice
) => {
  const offersToApply =
    !parseFloat(sellerDiscount) &&
      !isProductInSomePromotion(product, promotions)
      ? offers
      : {}

  const promotionsToApply = !parseFloat(sellerDiscount) ? promotions : {}

  const sellerDiscountWithProductDiscount =
    parseFloat(sellerDiscount || 0) + parseFloat(product.descuento || 0)

  const sellerDiscountToApply =
    sellerDiscountWithProductDiscount > 100
      ? 100
      : sellerDiscountWithProductDiscount

  const mainDiscount = getProductDiscount(
    product,
    cart,
    offersToApply,
    promotionsToApply,
    sellerDiscountToApply,
    branchOffice
  )
  const shouldHaveSecondaryDiscount = shouldProductHaveSecondaryDiscount(
    mainDiscount?.code
  )

  const productWithRestQuantity = {
    ...product,
    cantidad: mainDiscount?.notAffectedQuantity || 1,
  }

  const secondaryDiscount = shouldHaveSecondaryDiscount
    ? getProductDiscount(
      productWithRestQuantity,
      cart,
      [],
      {},
      sellerDiscount,
      branchOffice
    )
    : null

  return {
    mainDiscount,
    secondaryDiscount: secondaryDiscount
      ? {
        ...secondaryDiscount,
        affectedQuantity: mainDiscount?.notAffectedQuantity,
      }
      : null,
  }
}


export const promotionsCartChanges = (productsInCart, promotions) => {
  const changes = []

  promotions?.PR16?.forEach(pr => {
    let productsToApplyPromotion = productsInCart;

    if(pr.objetivoTipo === 'sku'){
      if(!pr.skuObj.find((skuObj) => productsInCart.some(({sku}) => skuObj === sku))) return;

      productsToApplyPromotion = productsToApplyPromotion.filter(({sku}) => pr.skuObj.includes(sku))
    }

    if(pr.objetivoTipo === 'categoria') {
      productsToApplyPromotion = productsToApplyPromotion.filter(({linea, subLinea, rubro}) => 
        [linea, subLinea, rubro].includes(pr.categoria)
      )
    }

    if(productsToApplyPromotion.length === 0) return;

    const productsAmount = productsToApplyPromotion.reduce((acc, pr) => { acc+= (Number(pr.cantidad) * pr.precio); return acc }, 0)
    if(pr.montoMinimo && productsAmount < pr.montoMinimo) return;

    productsToApplyPromotion.sort((a,b) => Number(a.sku) - Number(b.sku))

    if(pr.descuentoPesos) {
      let descuentoPesos = pr.descuentoPesos

      productsToApplyPromotion.forEach(product => {
        if(descuentoPesos <= 0) return
        const totalProductPrice = product.precio * product.cantidad
        const descuento = descuentoPesos >= totalProductPrice ? totalProductPrice : descuentoPesos
        const discountRate = (descuento / totalProductPrice) * 100;
        changes.push({
          sku: product.sku,
          discountRate: +discountRate.toFixed(2),
          descuento,
          cupon: pr.cupon,
          promotionCode: pr.codigo,
          promotionName: pr.cupon,
        })
        descuentoPesos -= descuento
      });
    } else if(pr.descuentoPorcentaje) {
      changes.push({
        sku: productsToApplyPromotion.map(({sku}) => sku),
        discountRate: pr.descuentoPorcentaje,
        descuento: (productsAmount / 100) * pr.descuentoPorcentaje,
        cupon: pr.cupon,
        promotionCode: pr.codigo,
        promotionName: pr.cupon,
      })
    }
  });

  promotions?.PR3?.forEach(pr => {
    if(!pr.skuObj.find((skuObj) => productsInCart.some(({sku}) => skuObj === sku)))
      return
    const productsToApplyPromotion = productsInCart.filter(({sku}) => pr.skuObj.includes(sku))
    const productsQuantity = productsToApplyPromotion.reduce((acc, pr) => { acc+= Number(pr.cantidad); return acc }, 0)
    const skuDeRegalo = pr.skuObjRegalo[0]
    const sku = productsToApplyPromotion.map(({sku}) => sku)
    const applyBannerTo = sku[0]
    const applyBannerToId = productsToApplyPromotion[0]?.id
    const quantity = Math.floor(productsQuantity / pr.cantidad)
    const discountRate = pr.porcentaje
    const currentPromo = {
      quantity: quantity > 1 ? 1 : quantity,
      skuDeRegalo,
      sku,
      applyBannerTo,
      applyBannerToId,
      cantidad: pr.cantidad,
      promotionName: pr.nombre,
      discountRate,
      productsToApplyPromotion,
      promotionCode: pr.codigo,
      cupon: pr.cupon
    }
    const anotherPromotionExistsIndex = changes.findIndex((c) => c.applyBannerTo === applyBannerTo && c.cupon === currentPromo.cupon)
    const anotherPromoExists = changes[anotherPromotionExistsIndex]
    
    if(anotherPromoExists?.cantidad < currentPromo.cantidad && quantity > 0){
      changes[anotherPromotionExistsIndex] = currentPromo
    } else if (!anotherPromoExists && quantity > 0) {
      changes.push(currentPromo)
    }
  });

  promotions?.PR10?.forEach(pr => {
    if(!pr.skuObj.find((skuObj) => productsInCart.some(({sku}) => skuObj === sku)))
      return

    const productsToApplyPromotion = productsInCart.filter(({sku}) => pr.skuObj.includes(sku))
    productsToApplyPromotion.sort((a,b) => Number(a.sku) - Number(b.sku))
    const productsQuantity = productsToApplyPromotion.reduce((acc, pr) => { acc+=Number(pr.cantidad); return acc }, 0)
    let skuDeRegalo = ''
    let sku = []
    let applyBannerTo = ''

    if(pr.destinoPromo === 'menor valor') {
      const sortedByPrice = [...productsToApplyPromotion]
      sortedByPrice.sort((a,b) => a.precioDeLista - b.precioDeLista)
      skuDeRegalo = sortedByPrice[0]?.sku
      sku = [sortedByPrice[0]?.sku]
    } 

    if(pr.destinoPromo === 'sku objetivo') {
      skuDeRegalo = pr.skuObjRegalo[0]
      sku = productsToApplyPromotion.map(({sku}) => sku)
    }
    applyBannerTo = sku[0]

    const promotionsToAdd = Math.floor(productsQuantity / pr.porCada)
    const quantity = promotionsToAdd * pr.regalo
    const discountRate = pr.porcentaje
    const currentPromo = {
      quantity,
      skuDeRegalo,
      sku,
      applyBannerTo,
      promotionName: pr.nombre,
      discountRate,
      porCada: pr.porCada,
      productsToApplyPromotion,
      promotionCode: pr.codigo
    }

    const anotherPromotionExistsIndex = changes.findIndex((c) => c.skuDeRegalo === skuDeRegalo)
    const anotherPromoExists = changes[anotherPromotionExistsIndex]

    if(anotherPromoExists && anotherPromoExists.porCada < currentPromo.porCada && quantity > 0){
      changes[anotherPromotionExistsIndex] = currentPromo
    } else if(!anotherPromoExists && quantity > 0){
      changes.push(currentPromo)
    }
  });

  promotions?.PR4?.forEach(pr => {
    if(!pr.skuObj.find((skuObj) => productsInCart.some(({sku}) => skuObj === sku)))
      return

    const productsToApplyPromotion = productsInCart.filter(({sku}) => pr.skuObj.includes(sku))
    const repeatedProducts = productsInCart.reduce((acc, product) => {
      if (pr.skuObj.includes(product.sku) && productsToApplyPromotion.some(({sku}) => sku === product.sku)) {
        acc[product.sku] = (acc[product.sku] || 0) + 1;
      }
      return acc;
    }, {});
    const hasRepeatedProducts = Object.values(repeatedProducts).some(count => count >= 2);

    if(hasRepeatedProducts) {
      productsToApplyPromotion.forEach(item => {
        const productsQuantity = item.cantidad
        const minorQuantityRequired = Math.min(...pr.llevandoDescuento.map(({desde}) => Number(desde)))
        let discountToApply = pr.llevandoDescuento.find(({desde, hasta}) => productsQuantity >= desde && productsQuantity <= hasta)?.descuento

        if(productsQuantity < minorQuantityRequired || !discountToApply) {
          discountToApply = null
        }
        const currentPromo = {
          promotionName: pr.nombre,
          updateDiscount: discountToApply,
          productsToApplyPromotion: [item],
          promotionCode: pr.codigo
        }
        return changes.push(currentPromo)
      });
    } else {
      const productsQuantity = productsToApplyPromotion.reduce((acc, pr) => { acc+= Number(pr.cantidad); return acc }, 0)
      const minorQuantityRequired = Math.min(...pr.llevandoDescuento.map(({desde}) => Number(desde)))
      let discountToApply = pr.llevandoDescuento.find(({desde, hasta}) => productsQuantity >= desde && productsQuantity <= hasta)?.descuento
    
      if(productsQuantity < minorQuantityRequired || !discountToApply) {
        discountToApply = null
      }
      const currentPromo = {
        promotionName: pr.nombre,
        updateDiscount: discountToApply,
        productsToApplyPromotion,
        promotionCode: pr.codigo
      }
      return changes.push(currentPromo)
    }
  });

  promotions?.PR5?.forEach(pr => {
    if(!pr.skuObj.find((skuObj) => productsInCart.some(({sku}) => skuObj === sku)))
      return
    const productsToApplyPromotion = productsInCart.filter(({sku}) => pr.skuObj.includes(sku))
    const productsQuantity = productsToApplyPromotion.reduce((acc, pr) => { acc+= Number(pr.cantidad); return acc }, 0)
    const discountToApply = pr.llevandoDescuento.find(({desde, hasta}) => productsQuantity >= desde && productsQuantity <= hasta)
    if(!discountToApply) return;

    productsToApplyPromotion.forEach(item => {
      const currentPromo = {
        promotionName: pr.nombre,
        discountRate: discountToApply.descuento,
        quantity: item.cantidad,
        productsToApplyPromotion,
        skuDeRegalo: item.sku,
        promotionCode: pr.codigo
      }
      changes.push(currentPromo)
    });
  });

  promotions?.PR6?.forEach(pr => {
    if(!pr.skuObj.find((skuObj) => productsInCart.some(({sku}) => skuObj === sku)))
      return
    const productsToApplyPromotion = productsInCart.filter(({sku}) => pr.skuObj.includes(sku))
    const productsAmount = productsToApplyPromotion.reduce((acc, pr) => { acc+= (Number(pr.cantidad) * pr.precio); return acc }, 0)
    if(productsAmount < pr.montoMinimo) return;

    productsToApplyPromotion.forEach(item => {
      const currentPromo = {
        promotionName: pr.nombre,
        discountRate: pr.porcentaje,
        quantity: item.cantidad,
        productsToApplyPromotion,
        skuDeRegalo: item.sku,
        promotionCode: pr.codigo
      }
      changes.push(currentPromo)
    });
  });

  changes.forEach((change, index) => {
    if(!change?.applyBannerTo) return;
    const productToApplyBanner = change.productsToApplyPromotion.find((p) => p.sku === change.applyBannerTo)
    let cantidadDeRegalo = change.quantity
    if(productToApplyBanner.cantidad < cantidadDeRegalo && productToApplyBanner.sku === change.skuDeRegalo) {
      changes[index].quantity = productToApplyBanner.cantidad
      cantidadDeRegalo = cantidadDeRegalo - productToApplyBanner.cantidad
      do {
        const otherProduct = change.productsToApplyPromotion.find((pr) => !changes.find((change) => change.sku?.includes(pr.sku) || pr.sku === change.applyBannerTo))
        if(!otherProduct) break
        const quantity = (otherProduct.cantidad > cantidadDeRegalo) ? cantidadDeRegalo : otherProduct.cantidad
        const newChange = {
          ...change,
          quantity,
          skuDeRegalo: otherProduct.sku,
          sku: [otherProduct.sku],
          applyBannerTo: otherProduct.sku
        }
        changes.push(newChange)
        cantidadDeRegalo = cantidadDeRegalo - newChange.quantity
      } while (cantidadDeRegalo > 0);
    }
  })
  
  return changes
}

export const getProductParticipatingInPromotion = (productsInCart, promotions) => {
  const participeInfo = []
  const { product, product_manejo, product_official } = productsInCart
  const allProducts = [...product, ...product_manejo, ...product_official]

  promotions?.PR10?.forEach(pr => {
    if(!pr.skuObj.find((skuObj) => allProducts.some(({sku}) => skuObj === sku)))
      return
    const productsToApplyPromotion = allProducts.filter(({sku}) => pr.skuObj.includes(sku))
    const productsQuantity = productsToApplyPromotion.reduce((acc, pr) => { acc+= pr.cantidad; return acc }, 0)
    const promotionsToAdd = Math.floor(productsQuantity / pr.porCada)
    const quantity = promotionsToAdd * pr.regalo
    
    const anotherPromoWithSameProductsIndex = 
      participeInfo.findIndex((promo) => 
        promo.products.every(({sku}) => 
          productsToApplyPromotion.some((pr) => pr.sku === sku)
        && promo.quantity === quantity
      ))
    
    if(anotherPromoWithSameProductsIndex >= 0 && participeInfo[anotherPromoWithSameProductsIndex]?.quantity === quantity) {
      participeInfo[anotherPromoWithSameProductsIndex].promotion.push(pr.nombre)
    } else {
      participeInfo.push({
        promotion: [pr.nombre],
        products: productsToApplyPromotion,
        redirectToCategory: pr.categoryLinked,
        quantity,
        quantityToPromo: pr.porCada - productsQuantity 
      })
    }
  })

  return participeInfo
}