import { Button, Dialog, Slide, Box, CircularProgress, Typography, TextField } from '@mui/material'
import { forwardRef, useEffect, useState } from "react"
import { useStyles } from './EditDiscountModal.styles'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function EditDiscountModal({submit, isOpen, close}) {
  const [newDiscount, setNewDiscount] = useState('')
  const styles = useStyles()

  const isInvalidDiscount = (discount) => {
    return isNaN(discount) || discount > 100 || discount < 0
  }

  const handleSubmit = () => {
    if(isInvalidDiscount(newDiscount))
      return alert('El valor ingresado es inválido');
    submit(Number(newDiscount || 0))
  }

  useEffect(() => {
    if(!isOpen){
      setNewDiscount('')
    }
  }, [isOpen])

  return (
    <Dialog
      open={isOpen} 
      onClose={close}
      transition={Transition}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <Box className={styles.container}>
          <TextField
            label={`Ingrese el nuevo descuento`}
            variant='outlined'
            value={newDiscount}
            fullWidth
            type="number"
            autoFocus
            className={styles.input}
            maxLength={3}
            onChange={(e) => {
              let {value} = e.target
              if(value > 100){
                value = 100
              }
              if(value < 0) {
                value = 0
              }
              setNewDiscount(value)
            }}
          />
          <Button type='submit'>
            Guardar
          </Button>
        </Box>
      </form>
    </Dialog>
  )
}