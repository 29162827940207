export const restrictedUsers = [
  '30712268979',
  '30714034215',
  '20104853405',
  '30715755919',
  '30708232153',
  '30593744880',
  '30708123982',
  '30586988359',
  '33562905389',
  '30607413254',
  '30607384319',
  '30707671404',
  '30675963793',
  '30684367958',
  '30716572478',
  '30613985995',
  '30711367485',
  '30714518549',
  '30709022209',
  '30710502931',
  '30622309730',
  '30715657933',
  '30712111131',
  '30501912405',
  '03458420553',
  '30711928142',
  '30708747099',
  '30544398012',
  '30688410890',
  '30708632267',
  '30711615403',
  '30713983205',
  '30619484807',
  '30716462311',
  '30710323034',
  '30707451536',
  '30694856116',
]

export const restrictedProducts = [
  '8600001',
  '8600002',
  '8600003',
  '8600004',
  '8600005',
  '8600006',
  '8600007',
  '8600009',
  '8600010',
  '8600011',
  '8600012',
  '8600013',
  '8600014',
  '8600015',
  '8600016',
  '8600017',
  '8600018',
  '8600019',
  '8600020',
  '8600021',
  '8600022',
  '8600023',
  '8600024',
  '8600025',
  '8600026',
  '8600027',
  '8600028',
  '8600029',
  '8600030',
  '8600031',
  '8600032',
  '8600033',
  '8600034',
  '8600035',
  '8600036',
  '8600037',
  '8600038',
  '8600039',
  '8600040',
  '8600041',
  '8600042',
  '8600043',
  '8600044',
  '8600045',
  '8600046',
]

export const shouldHidePrice = (user, productSku) => {
  const isRestrictedUser = restrictedUsers.includes(user)
  const isRestrictedProduct = restrictedProducts.includes(productSku)

  return isRestrictedUser && isRestrictedProduct
}
